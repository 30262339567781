import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { NavigationItem } from '@appkit4/angular-components/navigation';

@Component({
  selector: 'pwc-fdm-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent implements OnInit {
  solid = true;
  simple = true;
  collapsed = true;
  selectedIndex = 0;
  navList: NavigationItem[] = [
    {
      name: 'Funds',
      routerLink: '/main/fund',
      prefixIcon: 'custom-menu-view',
      prefixCollapsedIcon: 'custom-menu-view'
    },
    {
      name: 'Calculations',
      routerLink: '/main/scenario',
      prefixIcon: 'custom-news-report',
      prefixCollapsedIcon: 'custom-news-report'
    },
    {
      name: 'Source Data',
      routerLink: '/main/upload/sourcedata-list',
      prefixIcon: 'custom-document-export',
      prefixCollapsedIcon: 'custom-document-export'
    },
    {
      name: 'Users',
      routerLink: '/main/appuser',
      prefixIcon: 'custom-person',
      prefixCollapsedIcon: 'custom-person'
    },
    {
      name: 'General Ledger Codes',
      routerLink: '/main/general-ledger-account',
      prefixIcon: 'custom-tree-view',
      prefixCollapsedIcon: 'custom-tree-view'
    },
    {
      name: 'Help',
      prefixIcon: 'custom-help-question',
      routerLink: '/main/future-item',
      prefixCollapsedIcon: 'custom-help-question'
    }
  ];

  constructor(
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.toggleSidebarOptions();
    this.detectChanges();
  }

  private detectChanges(): void {
    this.changeDetectorRef.detectChanges();
  }

  // FIXME Need to be replaced with Kendo component
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  redirect(event: any): void {
    this.router.navigateByUrl(event.item.routerLink);
  }

  toggleSidebarOptions(): void {
    if (this.router.url) {
      if (this.router.url === '/main' || this.router.url.includes('fund')) {
        this.selectedIndex = 0;
      } else if (this.router.url.includes('scenario')) {
        this.selectedIndex = 1;
      } else if (this.router.url.includes('upload')) {
        this.selectedIndex = 2;
      } else if (this.router.url.includes('user')) {
        this.selectedIndex = 3;
      } else if (this.router.url.includes('general-ledger-account')) {
        this.selectedIndex = 4;
      } else {
        this.selectedIndex = 0;
      }
    }
  }
}

<kendo-dialog-titlebar>
  <div class="error-information-header">
    <span class="k-icon k-font-icon k-button-icon k-i-form-element"></span>Exception
  </div>
</kendo-dialog-titlebar>

<div class="error-information-content">
  <div class="name"><span class="label">Error:</span> {{ errorName || '' }}</div>
  <div class="message"><span class="label">Message:</span> {{ errorMessage || '' }}</div>
</div>

<kendo-dialog-actions>
  <div class="error-information-buttons">
    <button kendoButton (click)="close()">Close</button>
  </div>
</kendo-dialog-actions>

import { ViewEncapsulation } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

/* eslint-disable */
platformBrowserDynamic()
  .bootstrapModule(AppModule, {
    ngZoneEventCoalescing: true,
    defaultEncapsulation: ViewEncapsulation.None
  })
  .catch((err: any) => console.error(err));
/* eslint-enable */

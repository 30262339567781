import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { DialogRef, DialogContentBase } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'pwc-fdm-error-information',
  templateUrl: './error-information.component.html',
  styleUrls: ['./error-information.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorInformationComponent extends DialogContentBase {
  @Input() errorName = '';
  @Input() errorMessage = '';

  constructor(dialog: DialogRef) {
    super(dialog);
  }

  close(): void {
    this.dialog.close();
  }
}

<div id="app-container">
  <div class="app-wrapper">
    @if ((appuser$ | async)?.isAuthorized ?? false) {
      <div class="header-container">
        <pwc-fdm-header [title]="title" [appuser]="appuser$ | async"></pwc-fdm-header>
      </div>
    }

    <div class="content-container">
      @if ((appuser$ | async)?.isAuthorized ?? false) {
        <div class="sidebar-wrapper">
          <pwc-fdm-sidebar></pwc-fdm-sidebar>
        </div>
      }
      <div class="content-wrapper" [ngClass]="(appuser$ | async)?.isAuthorized ? 'container-with-scroll' : ''">
        <router-outlet></router-outlet>
        @if ((appuser$ | async)?.isAuthorized ?? false) {
          <div class="footer-container">
            <pwc-fdm-footer></pwc-fdm-footer>
          </div>
        }
      </div>
    </div>
  </div>
  <pwc-fdm-overlay *ngIf="(this.displayOverlay$ | async) ?? false"></pwc-fdm-overlay>
  <div kendoDialogContainer></div>
</div>

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import { environment } from '@app-environments/environment';

import { LoggerService } from '@app-core/services/logger.service';
import { AuthenticationService } from '@app-core/services/auth/authentication.service';
import { OverlayService } from '@app-core/layout/overlay/overlay.service';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  private env = environment;

  private loginUrl = '/home';
  private accessDeniedUrl = '/access-denied';

  constructor(
    private router: Router,
    private loggerService: LoggerService,
    private authenticateService: AuthenticationService,
    private overlayService: OverlayService
  ) {}

  // NOTE Do not remove this line as the request can be any type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.displayOverlay(request)) {
      this.overlayService.displayOverlay(true);
    }

    const started = Date.now();

    if (this.isApiUrl(request, this.env.apiUrl)) {
      // Expired id token, logout and redirect to home
      if (!this.authenticateService.hasValidIdToken()) {
        this.authenticateService.logout();
      }

      request = this.preConfigure(request);
    }

    return next.handle(request).pipe(
      map(event => {
        return event;
      }),
      catchError(err => {
        if (err.status === 401) {
          this.redirectTo(this.loginUrl);
        } else if (err.status === 403) {
          this.redirectTo(this.accessDeniedUrl);
        }
        this.processError(err);
        return throwError(() => err);
      }),
      finalize(() => {
        this.overlayService.displayOverlay(false);
        const elapsed = Date.now() - started;

        this.loggerService.logMessage(
          `Http request ${request.method} '${request.urlWithParams}' duration in ${elapsed} ms.`
        );
      })
    );
  }

  // NOTE Do not remove this line as the request can be any type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private preConfigure(request: HttpRequest<any>): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.authenticateService.getIdToken()}`,
        accessToken: `${this.authenticateService.getAccessToken()}`,
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: 'Sat, 01 Jan 2000 00:00:00 GMT'
      }
    });
  }

  // NOTE Do not remove this line as the request can be any type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private processError(err: any): void {
    if (err.error instanceof ErrorEvent) {
      // client-side error
      this.loggerService.logException(`Http interceptor - An error occurred: ${err?.error?.message ?? ''}`);
    } else {
      this.loggerService.logException(
        `Http interceptor - Server returned code: ${err.status}, error message is: ${err.message}`
      );
    }
  }

  // NOTE Do not remove this line as the request can be any type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private isApiUrl(request: HttpRequest<any>, apiUrl: string): boolean {
    return request.url.indexOf(apiUrl) !== -1;
  }

  private redirectTo(url: string): void {
    this.router
      .navigateByUrl(url)
      .then(() => this.loggerService.logMessage('Http interceptor - redirecting to ' + url));
  }

  // NOTE Do not remove this line as the request can be any type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private displayOverlay(request: HttpRequest<any>): boolean {
    if (
      (request.method === 'Post' && request.url.includes(`${environment.apiEndPoints.upload.uploadFile}`)) ||
      (request.method === 'Post' && request.url.includes(`${environment.apiEndPoints.upload.uploadMiscellaneousFile}`))
    ) {
      return false;
    }

    return true;
  }
}

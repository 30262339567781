import { ErrorHandler, Injectable } from '@angular/core';

import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';

import { LoggerService } from '../logger.service';

import { ErrorInformationComponent } from './error-information.component';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {
  private _errorDialogService: DialogService;

  constructor(
    private loggerService: LoggerService,
    errorDialogService: DialogService
  ) {
    this._errorDialogService = errorDialogService;
  }

  handleError(error: Error): void {
    this.loggerService.logException(error);

    this.showError(error);
  }

  private showError(error: Error | HttpErrorResponse): void {
    const dialogRef: DialogRef = this._errorDialogService.open({
      content: ErrorInformationComponent,
      cssClass: 'error-information-dialog',
      width: 650,
      minWidth: 250,
      height: 300
    });

    const errorInfoComponent = dialogRef.content.instance as ErrorInformationComponent;
    errorInfoComponent.errorName = error.name;
    errorInfoComponent.errorMessage = error.message;
  }
}

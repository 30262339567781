import { inject } from '@angular/core';
import { CanActivateFn, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';

import { AuthenticationService } from '@app-core/services/auth/authentication.service';

export const canActivateGuard: CanActivateFn = ():
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {
  const authenticationService = inject(AuthenticationService);

  return authenticationService.authenticated$;
};

import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

import { LoaderSize, LoaderThemeColor, LoaderType } from '@progress/kendo-angular-indicators';

@Component({
  selector: 'pwc-fdm-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverlayComponent {
  loaderType: LoaderType = 'converging-spinner';
  loaderColor: LoaderThemeColor = 'info';
  loaderSize: LoaderSize = 'large';
}

<div id="footer">
  <div class="footer-content">
    &#169; 2024 PricewaterhouseCoopers LLP, an Ontario limited liability partnership. All rights reserved. PwC refers to
    the Canadian member firm, and may sometimes refer to the PwC network. Each member firm is a separate legal entity.
    Please see
    <a class="ap-link" href="https://www.pwc.com/structure" target="_blank">www.pwc.com/structure</a>
    for further details.
  </div>
  <div class="footer-links">
    <a class="a-privacy-link" href="https://www.pwc.com/ca/en.html" target="_blank">Privacy policy</a> |
    <a class="a-terms-link" href="https://www.pwc.com/ca/en.html" target="_blank">Terms and conditions</a>
  </div>
</div>

<div id="sidebar">
  <ap-navigation
    [(selectedIndex)]="selectedIndex"
    [navList]="navList"
    [width]="'280px'"
    [solid]="solid"
    [hasHeader]="!simple"
    [collapsed]="collapsed"
    (onClick)="redirect($event)"
    [styleClass]="'side-bar-wrapper'"
    [showTooltip]="false">
  </ap-navigation>
</div>

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from '@app-core/services/auth/authentication.service';

import { AppuserModel } from '@app-shared/models/appuser.model';
import { HeaderItemModel } from './header-item.model';

import { AppPwcIcons } from '@app-shared/icons/app-pwc.icons';

@Component({
  selector: 'pwc-fdm-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnChanges, OnDestroy {
  @ViewChild('anchor', { static: false, read: ElementRef }) anchor!: ElementRef;

  @ViewChild('popupContainer', { static: false, read: ElementRef }) popupContainer!: ElementRef;

  @Input() title = '';
  @Input() appuser: AppuserModel | null = {} as AppuserModel;

  headerList: HeaderItemModel[] = [];
  userInitials = '';
  menuVisible = false;
  appPwcIcons = AppPwcIcons;
  margin = { horizontal: 5, vertical: 5 };

  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {}

  ngOnChanges(): void {
    this.headerList = [
      {
        value: 'UserProfile',
        label: `${this.appuser?.firstName} ${this.appuser?.lastName}`,
        styleClass: 'user-profile',
        iconName: this.appPwcIcons.outline.personIcon,
        callbackFunction: () => this.navigateProfile()
      },
      {
        value: 'UserEmail',
        label: this.appuser?.emailAddress
      },
      {
        value: 'UserRole',
        label: this.appuser?.appRoleName,
        styleClass: 'text-bold'
      },
      {
        value: 'Logout',
        label: 'Log Out',
        iconName: this.appPwcIcons.fill.logoutIcon,
        styleClass: 'logout'
      }
    ];
    if (this.appuser?.firstName && this.appuser.lastName) {
      this.userInitials = `${this.appuser.firstName.slice(0, 1)}${this.appuser.lastName.slice(0, 1)}`;
    }
  }

  //kendo event
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (this.menuVisible && this.anchor) {
      const anchorElement = this.anchor.nativeElement;
      if (!anchorElement.contains(event.target)) {
        this.hideMenu();
      }
    }
  }

  ngOnDestroy(): void {
    this.hideMenu();
  }

  onSelectItem(item: HeaderItemModel): void {
    switch (item.value) {
      case 'Logout':
        this.authService.logout();
        this.hideMenu();
        break;
    }
  }

  navigateProfile(): void {
    this.router.navigateByUrl('/main/appuser/appuser-profile');
    this.hideMenu();
  }

  hideMenu(): void {
    this.menuVisible = false;
  }

  displayMenu(): void {
    this.menuVisible = !this.menuVisible;
  }

  navigateToHome(): void {
    this.router.navigateByUrl('/main');
  }
}

<div id="header">
  <kendo-appbar position="top">
    <kendo-appbar-section>
      <div class="brand-logo">
        <img ngSrc="pwcLogoSimplified.svg" alt="PwC Logo" width="42" height="42" priority />
      </div>
      <span class="appbar-title">{{ title }}</span>
    </kendo-appbar-section>
    <kendo-appbar-spacer></kendo-appbar-spacer>

    <kendo-appbar-section>
      <div class="btn-avatar-section">
        <button kendoButton #anchor class="btn-anchor" (click)="displayMenu()">
          <kendo-avatar [initials]="userInitials"></kendo-avatar>
        </button>
      </div>
      <kendo-popup *ngIf="menuVisible" [anchor]="anchor.element" (close)="hideMenu()" [margin]="margin">
        <div #popupContainer class="popup-content">
          <div
            *ngFor="let item of headerList"
            (click)="onSelectItem(item)"
            [ngClass]="item.styleClass"
            class="ap-option-item">
            <div class="ap-option-left">
              <span class="ap-option-label" *ngIf="item.value !== 'Logout'">{{ item.label }}</span>
              @if (item.iconName) {
                <ng-container *ngIf="item.value === 'Logout'">
                  <span (click)="item.callbackFunction ? item.callbackFunction() : null">
                    <kendo-svgicon [icon]="item.iconName" size="large" class="header-menu-icon"></kendo-svgicon>
                  </span>
                  {{ item.label }}
                </ng-container>
                <ng-container *ngIf="item.value !== 'Logout'">
                  <span (click)="item.callbackFunction ? item.callbackFunction() : null">
                    <kendo-svgicon [icon]="item.iconName" size="large" class="header-menu-icon"></kendo-svgicon>
                  </span>
                </ng-container>
              }
            </div>
          </div>
        </div>
      </kendo-popup>
    </kendo-appbar-section>
  </kendo-appbar>
</div>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { canActivateGuard } from '@app-core/guard/can-activate.guard';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () => import('./features/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'component-library',
    loadChildren: () =>
      import('./features/component-library/component-library.module').then(m => m.ComponentLibraryModule)
  },
  {
    path: 'access-denied',
    loadChildren: () => import('./features/access-denied/access-denied.module').then(m => m.AccessDeniedModule)
  },
  {
    path: 'main',
    canActivate: [canActivateGuard],
    children: [
      { path: '', redirectTo: 'fund', pathMatch: 'full' },
      {
        path: 'appuser',
        loadChildren: () => import('@app-features/appuser/appuser.module').then(m => m.AppuserModule)
      },
      {
        path: 'fund',
        loadChildren: () => import('@app-features/fund/fund.module').then(m => m.FundModule)
      },
      {
        path: 'general-ledger-account',
        loadChildren: () =>
          import('@app-features/general-ledger-account/general-ledger-account.module').then(
            m => m.GeneralLedgerAccountModule
          )
      },
      {
        path: 'scenario',
        loadChildren: () => import('@app-features/scenario/scenario.module').then(m => m.ScenarioModule)
      },
      {
        path: 'upload',
        loadChildren: () => import('@app-features/upload/upload.module').then(m => m.UploadModule)
      },
      {
        path: 'future-item',
        loadChildren: () => import('@app-features/future-item/future-item.module').then(m => m.FutureItemModule)
      },
      { path: '**', redirectTo: 'funds' }
    ]
  },
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      onSameUrlNavigation: 'ignore',
      enableTracing: false,
      scrollPositionRestoration: 'top'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

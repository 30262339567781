import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OverlayService {
  private displayOverlayBehaviorSubject = new BehaviorSubject<boolean>(false);

  displayOverlay$ = this.displayOverlayBehaviorSubject.asObservable();

  displayOverlay(display: boolean): void {
    this.displayOverlayBehaviorSubject.next(display);
  }
}

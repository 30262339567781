// Angular
import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

// Third Party Modules
import { OAuthModule } from 'angular-oauth2-oidc';

// Appkit Modules
import { HeaderModule } from '@appkit4/angular-components/header';
import { NavigationModule } from '@appkit4/angular-components/navigation';

// Services
import { HttpInterceptorService } from './services/http-interceptor.service';
import { ErrorHandlerService } from './services/error/error-handler.service';

// Shared Module
import { SharedModule } from '@app-shared/shared.module';

// Kendo Modules
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { AppBarModule } from '@progress/kendo-angular-navigation';
import { AvatarModule } from '@progress/kendo-angular-layout';
import { DialogsModule } from '@progress/kendo-angular-dialog';

// Components
import { HeaderComponent } from './layout/header/header.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { FooterComponent } from './layout/footer/footer.component';
import { OverlayComponent } from '@app-core/layout/overlay/overlay.component';
import { ErrorInformationComponent } from '@app-core/services/error/error-information.component';

// NOTE Do not remove this line as the request can be any type
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function throwIfAlreadyLoaded(parentModule: any, moduleName: string): void {
  if (parentModule) {
    const msg = `${moduleName} has already been loaded. Import Core modules in the AppModule only.`;
    throw new Error(msg);
  }
}

@NgModule({
  declarations: [HeaderComponent, SidebarComponent, FooterComponent, OverlayComponent, ErrorInformationComponent],
  exports: [
    // Local
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    ErrorInformationComponent,
    SharedModule,
    //Kendo
    AppBarModule,
    OverlayComponent,
    IndicatorsModule,
    DialogsModule
  ],
  imports: [
    // Angular
    BrowserAnimationsModule,
    BrowserModule,
    RouterModule,
    SharedModule,
    // Appkit
    HeaderModule,
    NavigationModule,

    // Kendo
    AvatarModule,
    AppBarModule,
    IndicatorsModule,
    DialogsModule,
    // Third party
    OAuthModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService
    },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
